html {
  scroll-behavior: smooth;
}

/* 
    COMMON UTILS
*/

a,
a:hover {
  text-decoration: none;
  color: initial;
}

.br-8 {
  border-radius: 8px;
}

.br-3r {
  border-radius: 3rem;
}

.border-none {
  border: none;
}

.fs-16 {
  font-size: 1rem;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .fs-sm-14 {
    font-size: 0.875rem;
  }
}

.fs-18 {
  font-size: 1.125rem;
}

.fs-20 {
  font-size: 1.25rem;
}

.fs-28 {
  font-size: 1.75rem;
}

.fs-32 {
  font-size: 2rem;
}

.bb-light {
  border-bottom: 1px solid #eee;
}

.obj-fit--contain {
  object-fit: contain;
}

.w-15r {
  width: 15rem;
}

/* 
    HEADER
*/

.dropdown-menu {
  width: 17rem;
  & a:hover {
    color: #2f80ed;
  }
}

.header-sub {
  margin: 0.5rem auto;
  color: gray;
  font-size: 0.875rem;
  & td {
    padding: 2rem 5px;
    text-align: center;
  }
  & td:nth-child(2) {
    font-size: 22px;
    color: midnightblue;
    padding-bottom: 2rem;
  }
  & hr {
    background-color: #cacaca;
    width: 20px;
  }
}

/* 
    PRODUCTS 
*/

#searchfield {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  &:focus {
    outline: 0;
  }
}

#searchfield + .productsSearchIcon {
  position: absolute;
  right: 1.75rem;
  top: 0.75rem;
  color: darkgray;
}

.nav-item:last-of-type .nav-link {
  padding-bottom: 1.5rem;
}

.nav-link {
  color: midnightblue;
}

.nav-link:hover {
  color: darkslateblue;
}

/* 
    FEATUREDITEMS 
*/

.featured-items-bg {
  background: #fafafa;
  padding-top: 8rem;
  padding-bottom: 5rem;
  & .card-shadow {
    min-height: initial;
    box-shadow: 0 3px 8px #ccc;
    max-height: 17rem;
  }
}

.alt-bg:nth-of-type(1n) {
  background-color: #fa724c;
}

.alt-bg:nth-of-type(3n + 1) {
  background-color: #b12a5b;
}

.alt-bg:nth-of-type(2n) {
  background-color: #f7c524;
}

.alt-bg:nth-of-type(4n) {
  background-color: #19ca5a;
}

.featured-card-title {
  color: whitesmoke;
  font-size: 1.75rem;
}

.featured-btn {
  border-width: 2px;
  padding: 0.7rem 1.7rem;
}

.top-brands {
  margin-top: 5rem;
  margin-bottom: 5rem;
  & img {
    max-width: 175px;
    max-height: 100px;
  }
}

/* 
    HOMEPAGEPRODUCTSSHOWCASE
*/
.card-body img {
  transition: transform 0.3s ease-in-out;
}

.default-anchor-style,
.default-anchor-style:hover {
  text-decoration: none;
  color: initial;
}

.default-anchor-style:hover img {
  transform: scale(1.2);
  opacity: 0.9;
}

.product-showcase-cardheader {
  background: transparent;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 0 !important;
  border-top: 1px solid lightgray;
}

/* 
    FOOTER
*/
footer {
  background: #333;
  color: whitesmoke;
  font-size: 0.875rem;
}

/* 
    ABOUT PAGE
*/

.abt-para {
  font-size: 1.125rem;
  color: #444;
  text-align: justify;
}

/* 
    CONTACT PAGE
*/

#contact-cards .card-body {
  min-height: 214px;
}

.contact-card--1 {
  background: #8bc6ec;
  background: linear-gradient(62deg, #8bc6ec 0%, #9599e2 100%);
}

.contact-card--2 {
  background: rgb(238, 174, 202);
  background: linear-gradient(105deg, #eeaeca 0%, #72a0c1 100%);
}

.contact-card--3 {
  background: #fbab7e;
  background: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
}

.contact-card--4 {
  background: #08aeea;
  background: linear-gradient(160deg, #2af598 0%, #08aeea 100%);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .dropdown-menu {
    width: 100%;
  }
  .featured-card-title {
    font-size: 1.5rem;
  }
  .featured-items-bg > div {
    margin-left: auto !important;
    margin-right: auto !important;
    & .featured-card-title {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  }
  .top-brands > img {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .navbar-brand > img {
    width: 35px;
    height: 35px;
  }

  .navbar-brand > span {
    font-size: 0.75rem;
  }

  .navbar-brand + .navbar-toggler {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* 
  CONATCT US BUTTON
*/

.contactUsBtn {
  position: fixed;
  right: 1.5rem;
  bottom: 2rem;
  z-index: 1;
}
